<template>

<div>
  <div class="page">

    <div>
      <div  style="display: flex;height: 40px;padding-left: 10px;">


        <div style="font-weight: 600;">{{equipName}}</div>
      </div>


      <div class="item-box">
        <div>
          设备属性
        </div>


        <div @click="showPicker = true">
          {{state.properType.label}}
        </div>
        <van-popup v-model:show="showPicker" round position="bottom">
          <van-picker
            :columns="state.propertiesList"
            @cancel="showPicker = false"
            @confirm="onConfirm"
          />
        </van-popup>
      </div>

      <div class="item-box">
        <div>
          开始时间
        </div>

        <div @click="showPicker2 = true">
          {{toSlashDate(startDate)}}
        </div>
        <van-popup v-model:show="showPicker2" round position="bottom">


          <van-picker-group
            title="开始时间"
            :tabs="['选择日期', '选择时间']"
            @confirm="onConfirm2"
            @cancel="onCancel"
          >
            <van-date-picker
              v-model="currentDate2"
              :min-date="minDate"
              :max-date="maxDate"
            />


            <van-time-picker v-model="currentTime2" :columns-type="['hour', 'minute', 'second']"/>
          </van-picker-group>
        </van-popup>
      </div>

      <div class="item-box">
        <div>
          结束时间
        </div>

        <div @click="showPicker3 = true">
          {{toSlashDate(endDate)}}
        </div>
        <van-popup v-model:show="showPicker3" round position="bottom">


          <van-picker-group
            title="结束时间"
            :tabs="['选择日期', '选择时间']"
            @confirm="onConfirm3"
            @cancel="onCancel"
          >
            <van-date-picker
              v-model="currentDate3"
              :min-date="minDate"
              :max-date="maxDate"
            />


            <van-time-picker v-model="currentTime3" :columns-type="['hour', 'minute', 'second']"/>
          </van-picker-group>
        </van-popup>
      </div>


      <van-button @click="submit" style="width: 100%;" type="primary">确认</van-button>
    </div>


    <div v-show="state.xAxisData.length==0" style="margin-top: 60px;">暂无数据</div>


    <van-loading v-if="showLoading" type="spinner" color="#1989fa"  style="margin-top: 60px;"/>






  </div>
  <div v-show="!showLoading&&state.xAxisData.length!=0"  ref="chartRef" style="width: 100%; height: 400px;"></div>
</div>
</template>

<script setup>

import * as echarts from 'echarts';

import { ref, onMounted,reactive,nextTick  } from 'vue';

import { showToast } from 'vant';

import dayjs from 'dayjs';

// import { useRouter } from 'vue-router'
// const router = useRouter()


import {queryEquipProperties,queryDevicePropertiesByEquId} from '../../api'

const toSlashDate = str => str?.replaceAll('-', '/') || ''


    onMounted( async()=>{


      const params = new URLSearchParams(window.location.search);

      equipId.value = params.get('equipId');

      mainId.value = params.get('mainId');


      equipName.value =  params.get('equipName');


      queryEquipProperties( mainId.value).then(res=>{

        state.properType=res.data[0]

        state.propertiesList = res.data.map(item=>{

          item.text =item.label


          return item
        })


        getChartData()
      }).catch((e)=> { console.log('e',e) })

    })


    const equipId = ref()
    const mainId = ref()

    const equipName = ref()


    const chartRef = ref();

    const showLoading = ref(false)


    let state = reactive({

      propertiesList:[],

      xAxisData:[],
      seriesData:[],
      properType:{}
    })


    const startDate = ref(dayjs(dayjs().format('YYYY-MM-DD HH:mm:ss')).subtract(24, 'hour').format('YYYY-MM-DD HH:mm:ss'))

    const endDate = ref(dayjs().format('YYYY-MM-DD HH:mm:ss'))

    const getChartData=async()=>{
        let res
        showLoading.value = true
      try{
            res = await queryDevicePropertiesByEquId({
            begin: startDate.value,
            column: state.properType.value,
            end: endDate.value,
            equipId: equipId.value
        })
        } catch (e) {
        showLoading.value = false
        console.log('e', e)
        return
      }


        let arr = res.split('data:{');


        let list = []
        arr.map(item=>{
          if(item != ''){
            // let str = item.substring(5);
            let str = '{'+item;

            try{
              let res = JSON.parse(str)

              list = list.concat(res.data)
            }catch(e){
              //TODO handle the exception
              showToast( 'JSON数据格式错误');
            }
          }

        })



        state.xAxisData = list.map(x=> toSlashDate(x[0]))

        state.seriesData = list.map(item=>{
          return item[1]
        })


        initChart();
    }


    let myChart = null
    const initChart =()=> {
      myChart  = echarts.init(chartRef.value);


      // 在这里设置ECharts的配置选项
      const options = {

          tooltip: {
              trigger: 'axis'  // 默认的 tooltip 触发类型为坐标轴触发
          },
          xAxis: {
            type: 'category',
            data: state.xAxisData,
            axisLabel: {
                margin: 10, // 设置文字与坐标轴之间的距离
                fontSize: 8, // 设置文字的字体大小
                offset: [0, 20],

            },
          },
          yAxis: {
            type: 'value',
            axisLabel: {
                margin: 5, // 设置文字与坐标轴之间的距离
                fontSize: 8, // 设置文字的字体大小

            },
            axisLine: {
                show: true // 显示 Y 轴线
            },
          },
          series: [{
            data: state.seriesData,
            type: 'line',
            areaStyle: {}
          }]
      };

      myChart.setOption(options);



      nextTick(() => {
        if (!showLoading.value) {
          myChart.resize();
        }
      });
      showLoading.value = false
    }


    const showPicker = ref(false);

    const showPicker2 = ref(false);

    const showPicker3 = ref(false);




    const onConfirm = ({ selectedOptions }) => {
      showPicker.value = false

      state.properType = selectedOptions[0]
    };

    function GetDefaultDate (date){

      let endDateText = date.slice(0,10)

      let endDateArr = endDateText.split('-')


      let endDateText2 = date.slice(10)

      let endDateArr2 = endDateText2.split(':')


      this.date = endDateArr
      this.time = endDateArr2

    }

    let startValue = new GetDefaultDate(startDate.value)

    let endValue = new GetDefaultDate(endDate.value)

    console.log(startValue)
    const currentDate2 = ref(startValue.date);

    const currentDate3 = ref(endValue.date);

    const currentTime2 = ref(startValue.time);

    const currentTime3 = ref(endValue.time);

    const onConfirm2 = () => {
      let date = `${currentDate2.value.join('-')} ${currentTime2.value.join(':')}`

      startDate.value = date

      showPicker2.value =false
    }

    const onConfirm3 = () => {
      let date = `${currentDate3.value.join('-')} ${currentTime3.value.join(':')}`

      endDate.value = date
      showPicker3.value =false
    }

    const onCancel = () => {
      // showToast('cancel');
      showPicker2.value = false
      showPicker3.value = false
    }

    const submit=()=>{


      let ret = dayjs(endDate.value).diff(dayjs(startDate.value));
				if (ret <= 0) {


          showToast('开始时间应该小于结束时间')


          return
        }


				ret = dayjs(endDate.value).diff(dayjs(startDate.value), 'day');


				if (ret > 31) {

          showToast('时间间隔不能超过1个月');

					return
				}



      getChartData()
      const options = {
          xAxis: {
            type: 'category',
            data: state.xAxisData,
          },
          yAxis: {
            type: 'value'
          },
          series: [{
            data: state.seriesData,
            type: 'line',
            areaStyle: {}
          }]
      };

      myChart && myChart.setOption(options);

    }


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped >

  .page{

    padding:0 20px;
  }

  .item-box{
      border: 1px solid #ccc;
      width: calc(100% - 40px);
      height: 40px;
      border-radius: 30px;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
    }
</style>
