// request.js

import axios from 'axios';

const instance = axios.create({
  baseURL: 'https://gateway.prod.forwayapp.com/', // 所为智控
  //baseURL: 'https://gateway.nx.forwayapp.com/', // 能效私有化
  // baseURL: 'http://gatewaysys.test.forwayapp.com/', // 系统级电量
  timeout: 10000, // 设置超时时间
});

// 请求拦截器
instance.interceptors.request.use(
  (config) => {
    // 在请求发送之前做一些处理
    const params = new URLSearchParams(window.location.search);

    const token = params.get('token');

    config.headers['Authorization'] = token;
    return config;
  },
  (error) => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 响应拦截器
instance.interceptors.response.use(
  (response) => {
    // 对响应数据做些处理
    return response.data;
  },
  (error) => {
    // 对响应错误做些什么
    return Promise.reject(error);
  }
);

export default instance;
