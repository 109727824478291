import request from '../utils/request';


export const queryEquipProperties = (id) => {
   return request.get(`/platform/applet/queryEquipProperties?equipId=${id}`)
};


export const queryDevicePropertiesByEquId=(data)=>{
    return request.post(`/platform/engineering/node/queryDevicePropertiesByEquIdBySSE`,data)
}